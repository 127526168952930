import React from "react";
import { useState } from "react";
import { Button } from '@mui/material'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./NewUser.css";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import token from "../token";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { userCreation } from "../api";
import { useAuthState } from "@bamboobox/b2logincheck";
import Autocomplete from '@mui/material/Autocomplete';
// import isEmail from 'validator/lib/isEmail';
import TextField from '@mui/material/TextField';
import { useEffect } from "react";


const useStyles = makeStyles(theme => ({
  form:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"20px"
  },
  largeBtn: {
      width: 120
  },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    width: 450,
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  superUser:{
    display:"flex",
    alignItems:"center",
    marginBottom:"10px"
  },
  buttonsHolder:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"20px"
  },
  col:{
    marginBottom:"10px"
  },
  dialogContent:{
    margin: "auto",
    marginTop: "15px",
  },
 }));

export default function NewUser(props) {
  const classes = useStyles();
  const userData = useAuthState();
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [nameError,setNameError]=useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [nameText,setNameText]=useState("");
  const [lastNameText, setLastNameText]=useState("");
  const [emailError,setEmailError]=useState(false);
  const [emailText,setEmailText]=useState("");
  const [superUser,setSuperUser]=useState(false);
  const [selectedRoles,setSelectedRoles]=useState([]);
  const [selectedDag,setSelectedDag]=useState(null);
  
  const handleSave = async() => {
    console.log('validateEmail(email)', validateEmail(email), firstName);
    const specialCharRegex = /[<>\/"'`;&(){}[\]]/;
    if (!firstName || !email) {
      setNameError(true);
      setNameText("required");
      setEmailError(true);
      setEmailText("required")
    } else if(!firstName || specialCharRegex.test(firstName) || firstName.length > 128) {
      setNameError(true);
      setNameText("Required or special characters not allowed or max length is 128");
    } else if(!lastName || specialCharRegex.test(lastName) || lastName.length > 128) {
      setLastNameError(true);
      setLastNameText("Required or special characters not allowed or max length is 128");
    } else if(!email) {
      setEmailError(true);
      setEmailText("required")
    } else if (!validateEmail(email)) {
      setEmailError(true);
      setEmailText("not valid")
    } else {
      try {
        const response = await userCreation({department,email,firstName,lastName,superUser,selectedRoles,selectedDag});
        setFirstName("");
        setLastName("");
        setEmail("");
        setDepartment("");
        setSuperUser(false);
        props.renderHandler();
        props.setMessage("Created Successfully");
        props.setOpen(true);
      } catch (error) {
        props.setMessage("Unable to create try again");
        props.setOpen(true);
      }
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setSelectedRoles([]);
      setSelectedDag(null);
      setNameText('');
      setLastNameText('');
      setNameError('');
      setLastNameError('');
      setEmailError('');
    }
  };

  const handleFirstName = (e) => {
    const specialCharRegex = /[<>\/"'`;&(){}[\]]/;
    console.log('Target', e.target.value)
    if (e.target.value?.length > 128){
      setNameError(true);
      setNameText("max-length allowed is 128");
      return;
    }
    if (e.target.value === "") {
      setNameError(true);
      setNameText("required")
    } else if (specialCharRegex.test(e.target.value)) {
      setNameError(true);
      setNameText("Invalid input, special characters not allowed");
    } else if (/['";\-]/.test(e.target.value)) {
      setNameError(true);
      setNameText("Invalid input, potential SQL injection detected");
    } else {
      setNameError(false);
      setNameText("");
    }
    setFirstName(e.target.value)
  }
  const handleLastName = (e) => {
    const specialCharRegex = /[<>\/"'`;&(){}[\]]/;
    if (e.target.value?.length > 128) {
      setLastNameError(true);
      setLastNameText("max-length allowed is 128");
      return;
    }
    if (e.target.value === "") {
      setLastNameError(true);
      setLastNameText("required")
    } else if (specialCharRegex.test(e.target.value)) {
      setLastNameError(true);
      setLastNameText("Invalid input, special characters not allowed");
    } else if (/['";\-]/.test(e.target.value)) {
      setLastNameError(true);
      setLastNameText("Invalid input, potential SQL injection detected");
    } else {
      setLastNameError(false);
      setLastNameText("");
    }
    setLastName(e.target.value)
  }
  const handleEmail=(e)=>{
    if(e.target.value===""){
      setEmailError(true);
      setEmailText("required")
    }else{
      setEmailError(false);
      setEmailText("");
      }
    setEmail(e.target.value);
  }
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRoleChange=(prop)=>{
    const newData=prop.filter((obj)=>obj.roleId !== "0000");
    setSelectedRoles(newData);
  }

  const handleDagChange=(prop)=>{
    // const newData=prop.filter((obj)=>obj.roleId !== "0000");
    setSelectedDag(prop);
  }

  const optionDisabledRole=(option)=>{
    const names=selectedRoles.map((row)=>{return row.roleName});
    return names.includes(option.roleName)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          }} >
        +New User
      </Button>
      <Dialog
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Edit"
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title">{"Edit User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          <div>
                <FormControl >
                <label style={{color:"#212121"}}>FirstName</label>
                 <TextField
                    id="outlined-disabled"
                    value={firstName}
                    error={nameError}
                    helperText={nameText}
                    onChange={e=>handleFirstName(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl  required={true} >
                <label style={{color:"#212121"}}>LastName</label>
                 <TextField
                    id="outlined-disabled"
                    value={lastName}
                    helperText={lastNameText}
                    error = {lastNameError}
                    onChange={e=>handleLastName(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Email</label>
                 <TextField
                    id="outlined-disabled"
                    value={email}
                    error={emailError}
                    helperText={emailText}
                    onChange={e=>handleEmail(e)}
                    autoComplete="email"
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
            {/* <div style={{margin:"5px 0px"}}>
                <InputLabel id="demo-simple-select-outlined-label" style={{display:"inline" ,color:"#212121"}}>
                        SUPERUSER:
                </InputLabel>
                <Checkbox color="primary" checked={superUser} onChange={e=>setSuperUser(!superUser)} />
            </div> */}
            {/* <div>
                  <InputLabel id="demo-simple-select-outlined-label" style={{color:"#212121"}}>
                    Department
                  </InputLabel>

                  <Select
                    style={{width:"300px"}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={department}
                    onChange={e=>setDepartment(e.target.value)}
                    label="Department"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="IS Executive">IS Executive</MenuItem>
                    <MenuItem value="FS Executive">FS Executive</MenuItem>
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Data Team">Data Team</MenuItem>
                    <MenuItem value="Field Sales">Field Sales</MenuItem>
                    <MenuItem value="Inside Sales">Inside Sales</MenuItem>
                    <MenuItem value="Sales Ops">Sales Ops</MenuItem>
                  </Select>
              </div> */}
        <FormControl variant="outlined" >
          <div>
            <label style={{color:"#212121"}}>Roles</label>
            <Autocomplete
                multiple
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={(e,newValue)=>handleRoleChange(newValue)}
                id="multiple-limit-tags"
                options={props.roles}
                getOptionLabel={(option) => option.roleName}
                value={selectedRoles}
                getOptionDisabled={(option)=>optionDisabledRole(option)}
                renderInput={(params) => (
                <TextField {...params} label="Select Role" variant="outlined"/>
                )}
                style={{width:400}}
            />
          </div>
        </FormControl>
        <FormControl variant="outlined" >
          <div>
            <label style={{color:"#212121"}}>DAG</label>
            <Autocomplete
                freeSolo
                onChange={(e,newValue)=>handleDagChange(newValue)}
                id="single-limit-tags"
                options={props.dag}
                getOptionLabel={(option) => option.dagName}
                value={selectedDag}
                renderInput={(params) => (
                <TextField {...params} label="Select DAG" variant="outlined"/>
                )}
                style={{width:400}}
            />
          </div>
        </FormControl>
        <DialogActions className={classes.buttonsHolder}>
          <Button className={classes.largeBtn} onClick={handleClose}  variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Close
          </Button>
          <Button className={classes.largeBtn} onClick={handleSave} variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Create
          </Button>
        </DialogActions>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
</div>
  );
}
